import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import TwitterIcon from "@mui/icons-material/Twitter"
import YouTubeIcon from "@mui/icons-material/YouTube"
import { Hr } from "./Layouts/MainLayout"
import Twitch from "./Icons/Twitch"
import Link from "@mui/material/Link"
import { styled } from "@mui/system"

export default function SideBar() {
	const ImageHeader = React.useMemo(
		() => (
			<div style={{ margin: "1rem" }}>
				<StaticImage
					src="../images/Edward-Almanzar.png"
					alt="Edward Almanzar"
					// loading="eager"
					layout="constrained"
					placeholder="blurred"
					width={150}
					height={150}
					// className={classes.Avatar}
					style={{
						borderRadius: "3rem",
						verticalAlign: "middle",
						margin: "1rem",
						padding: "1rem",
					}}
				/>
			</div>
		),
		[]
	)

	const Container = styled("div")(({ theme }) => ({
		display: "flex",
		flexDirection: "column",
		gap: 5,
		position: "sticky",
		top: "5%",
		overflow: "auto",
		textAlign: "center",
		minHeight: "200px",
		[theme.breakpoints.up("md")]: {
			gap: 0,
		},
	}))

	return (
		<Container>
			{ImageHeader}
			<Typography color="secondary" component="h1" variant="h5" align="center">
				Edward Almanzar
			</Typography>
			<Hr />

			<Typography
				color="secondary"
				align="center"
				sx={{
					display: "flex",
					justifyContent: "center",
					margin: "9px 0 9px 0",
				}}
			>
				<LocationOnIcon /> New York
			</Typography>
			<Typography
				color="secondary"
				component="h2"
				variant="h6"
				align="center"
				sx={{
					backgroundColor: "background.default",
					borderRadius: "1rem",
					margin: "9px 0 9px 0",
				}}
			>
				Software Engineer
			</Typography>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-evenly",
					margin: "1rem 0 1rem 0",
					"& > a > svg:hover": {
						bgcolor: "background.default",
						p: 0.5,
						// m: "1px",
					},
				}}
			>
				<Link
					aria-label="Github Profile"
					href="https://github.com/edardev"
					target="_blank"
					rel="noopener noreferrer"
				>
					<GitHubIcon color="secondary" fontSize="large" />
				</Link>
				<Link
					aria-label="Linkedin Profile"
					href="https://www.linkedin.com/in/edward-almanzar/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<LinkedInIcon color="secondary" fontSize="large" />
				</Link>
				<Link
					aria-label="YouTube channel"
					href="https://www.youtube.com/channel/UCg65BLgehZQFDJjv97kFllQ"
					target="_blank"
					rel="noopener noreferrer"
				>
					<YouTubeIcon color="secondary" fontSize="large" />
				</Link>
				<Link
					aria-label="Twitter Profile"
					href="https://twitter.com/edardev"
					target="_blank"
					rel="noopener noreferrer"
				>
					<TwitterIcon color="secondary" fontSize="large" />
				</Link>
				<Link
					aria-label="Twitch Profile"
					href="https://www.twitch.tv/edardev"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Twitch color="secondary" fontSize="large" />
				</Link>
			</Box>

			<Typography component="h3" variant="body1" align="center">
				<Link
					href="mailto: Edward@menteware.com"
					sx={{
						display: "flex",
						justifyContent: "center",
						backgroundColor: "background.default",
						borderRadius: "1rem",
						margin: "1rem 0 1rem 0",
						p: 1,
						"&:hover": {
							fontSize: "97%",
						},
					}}
					color="secondary"
				>
					<MailOutlineIcon /> Edward@menteware.com
				</Link>
			</Typography>
		</Container>
	)
}
