// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
import React, { createContext } from "react"
import useLocalStorage from "../hooks/useLocalStorage"

type ThemeCxtType = {
	theme: "light" | "dark"
	toggleTheme: () => void
}

export const ThemeContext = createContext<ThemeCxtType>({} as ThemeCxtType)

type ThemeProviderProps = {
	children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
	const [theme, setTheme] = useLocalStorage<"light" | "dark">("theme", "dark")

	const toggleTheme = () =>
		setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"))

	return (
		<ThemeContext.Provider
			value={{
				theme,
				toggleTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	)
}
