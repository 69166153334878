import * as React from "react"
import { Helmet } from "react-helmet"
import CssBaseline from "@mui/material/CssBaseline"
import {
	ThemeProvider,
	createTheme,
	StyledEngineProvider,
	responsiveFontSizes,
} from "@mui/material/styles"

import useDarkTheme from "../../src/hooks/useDarkTheme"

type TopLayoutProps = {
	children: React.ReactNode
}
export default function TopLayout({ children }: TopLayoutProps) {
	const { themeObj } = useDarkTheme()
	let theme = React.useMemo(() => createTheme(themeObj), [themeObj])
	theme = responsiveFontSizes(theme)

	return (
		<React.Fragment>
			<Helmet>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width"
				/>
				{/* <link
					href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
					rel="stylesheet"
				/> */}
			</Helmet>
			<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				{children}
			</ThemeProvider>
		</React.Fragment>
	)
}
