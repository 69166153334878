import { useContext } from "react"
import { ThemeContext } from "../context/theme"

import { ThemeOptions } from "@mui/material"

export default function useDarkTheme() {
	const { theme, toggleTheme } = useContext(ThemeContext)
	const themeObj = theme === "light" ? lightThemeObj : darkThemeObj

	// useEffect(() => {
	// 	if (theme === Theme.Dark) {
	// 		document.documentElement.classList.add(Theme.Dark)
	// 		document.body.style.backgroundColor = "black"
	// 	} else {
	// 		document.documentElement.classList.remove(Theme.Dark)
	// 		document.body.style.backgroundColor = "white"
	// 	}
	// }, [theme])

	return { theme, toggleTheme, themeObj }
}

export const darkThemeObj: ThemeOptions = {
	palette: {
		// type: prefersDarkMode ? "dark" : "light"
		// mode: "dark",
		text: {
			primary: "#FAFAFA",
			secondary: "yellow",
		},
		primary: {
			// light: will be calculated from palette.primary.main,
			main: "#FFF",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			// light: will be calculated from palette.primary.main,
			main: "#0FACF3",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		background: {
			default: "#3b3b3b",
			paper: "#2a2a2a",
		},
	},
}

export const lightThemeObj: ThemeOptions = {
	palette: {
		// mode: "light",
		text: {
			primary: "#000",
			secondary: "#020202",
		},
		primary: {
			main: "#000",
		},
		secondary: {
			main: "#000",
		},
		background: {
			// default: "#F8FBFD",
			default: "#EFEFEF",
			// paper: orange[100],
			paper: "#FFE9A2",
			// paper: "#F8D775",
		},
	},
}
