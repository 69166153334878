/* eslint-disable import/prefer-default-export */
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import { ThemeProvider } from "../../src/context/theme"
import TopLayout from "./TopLayout"
import MainLayout from "../../src/components/Layouts/MainLayout"
import "../../src/styles/global.css"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
	element,
	props,
}: any) => {
	return (
		<ThemeProvider>
			<TopLayout>
				<MainLayout {...props}>{element}</MainLayout>
			</TopLayout>
		</ThemeProvider>
	)
}
