import React from "react"
import { styled } from "@mui/system"
// import { styled } from "@mui/material/styles"
import { AppBar, Box, Toolbar } from "@mui/material"

import NavSVG from "../../images/nav-edge.svg"
import BtnLink from "../BtnLink"
import cssHexToCssFilter from "../../utils/cssHexToCssFilter"

const TemporaryDrawerCSR = React.lazy(() => import("../Drawer"))
const ThemeSwitchCSR = React.lazy(() => import("../ThemeSwitch"))

const MyAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	position: "absolute",
	[theme.breakpoints.up("md")]: {
		backgroundColor: theme.palette.background.default,
		flexDirection: "row-reverse",
		boxShadow: "0 0 0 0",
		borderRadius: "0 3rem 0 0",
		position: "relative",
		zIndex: 0,
	},
}))

const MyToolBar = styled(Toolbar)(({ theme }) => {
	let cssFilterValue = cssHexToCssFilter(theme.palette.background.paper)
	return {
		flexDirection: "row",
		justifyContent: "space-between",
		"& > a": {
			display: "none",
		},
		"& > .anchor-active": {
			backgroundColor: theme.palette.background,
			border: "0.13rem solid #0FACF3 !important",
			"&:hover": {
				backgroundColor: theme.palette.background,
			},
		},
		[theme.breakpoints.down("sm")]: {
			"& > div:nth-last-of-type(2)": {
				flexGrow: 1,
				marginLeft: "50%",
			},
		},
		[theme.breakpoints.up("md")]: {
			// flexDirection: "row-reverse",
			backgroundColor: theme.palette.background.paper,
			borderRadius: "0 3rem 0 0",
			"& > a": {
				display: "inline",
				marginRight: theme.spacing(1),
				border: "0.13rem solid transparent",
			},
			"& > a:focus": {
				// border: "0.13rem solid #0FACF3 !important",
				backgroundColor: theme.palette.background,
			},
			position: "relative",
			"&::before": {
				content: "''",
				filter: `${cssFilterValue}`,
				backgroundImage: `url(${NavSVG})`,
				height: "100%",
				position: "absolute",
				top: "0.5px",
				left: "-120px",
				width: "135px",
				zIndex: "-1",
			},
		},
	}
})

const NavBtn = styled(BtnLink)(({ theme }) => ({
	backgroundColor: theme.palette.background,
	// ...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}))

const MenuAppBar = () => {
	const isSSR = typeof window === "undefined"
	return (
		<Box sx={{ flexGrow: 1 }}>
			<MyAppBar>
				<MyToolBar>
					{!isSSR && (
						<React.Suspense fallback={<div />}>
							<TemporaryDrawerCSR />
						</React.Suspense>
					)}
					<NavBtn to="/" activeClassName="anchor-active">
						Home
					</NavBtn>
					<NavBtn to="/blog" activeClassName="anchor-active">
						Blog
					</NavBtn>
					<NavBtn to="/videos" activeClassName="anchor-active">
						Videos
					</NavBtn>
					<NavBtn to="/contact" activeClassName="anchor-active">
						Contact
					</NavBtn>
					<NavBtn to="/resume" activeClassName="anchor-active">
						Resume
					</NavBtn>

					{!isSSR && (
						<React.Suspense fallback={<div />}>
							<ThemeSwitchCSR />
						</React.Suspense>
					)}
				</MyToolBar>
			</MyAppBar>
		</Box>
	)
}

export default MenuAppBar
