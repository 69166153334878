import { hexToCSSFilter, HexToCssConfiguration } from "hex-to-css-filter"

const cssHexToCssFilter = (CssHexValue: string): string => {
	let cssFilterValue = ""
	const config: HexToCssConfiguration = {
		acceptanceLossPercentage: 1,
		maxChecks: 10,
	}
	const cssFilter = hexToCSSFilter(CssHexValue, config)
	cssFilterValue = cssFilter.filter.replace(";", "")
	return cssFilterValue
}

export default cssHexToCssFilter
