import { useState, useEffect } from "react"

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>]

function getValue<T>(key: string, initialValue?: T) {
	if (typeof window === "undefined") return initialValue
	const savedValue = localStorage.getItem(key)
	if (savedValue) return JSON.parse(savedValue)
	if (initialValue instanceof Function) return savedValue
	return initialValue
}

export default function useLocalStorage<T>(
	key: string,
	initialValue?: T
): ReturnType<T> {
	const [value, setValue] = useState<any>()

	useEffect(() => {
		setValue(() => getValue(key, initialValue))
		return localStorage.setItem(key, JSON.stringify(value))
	}, [value, key, initialValue])

	return [value, setValue]
}
