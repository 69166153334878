import * as React from "react"
import { PageProps } from "gatsby"
import { Grid, Paper, ScopedCssBaseline, Container } from "@mui/material"
import { styled } from "@mui/material/styles"

import MenuAppBar from "../MenuAppBar"
import SideBar from "../SideBar"
const FooterClientSideOnlyLazy = React.lazy(() => import("../Footer"))

const Hr = styled("hr")(({ theme }) => ({
	boxShadow: "rgb(255 255 255 / 7%) 0 1px 0 !important",
	border: `1px solid ${"#222"}!important`,
	borderWidth: "2px",
	margin: 0,
	padding: 1,
}))

export default function MainLayout({ children, location }: PageProps) {
	const isSSR = typeof window === "undefined"
	const LayoutRoot = styled(Container)(({ theme }) => ({
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			marginTop: "auto",
			padding: "1rem 3.37rem 1.87rem 3.37rem ",
			height: "100vh",
			marginBottom: "20rem",
			maxWidth: "1464px",
		},
	}))

	const LayoutContainer = styled(Container)(({ theme }) => ({
		padding: 0,
		marginTop: "70px",
		[theme.breakpoints.up("md")]: {
			padding: 0,
			margin: 0,
			maxWidth: "1464px",
		},
	}))

	const ContainerPaper = styled(Paper)(({ theme }) => ({
		backgroundColor: "theme.palette.background.paper",
		marginBottom: "3rem",
		padding: 0,
		[theme.breakpoints.up("md")]: {
			padding: "0.87rem 0 0.87rem 0",
			borderRadius: "3rem 0 3rem 3rem",
		},
	}))

	const MainContentGrid = styled(Grid)(({ theme }) => ({
		height: "100%",
		padding: "1rem",
		[theme.breakpoints.up("md")]: {
			minHeight: "700px",
		},
	}))

	const SideBarGrid = styled(Grid)(({ theme }: any) => ({
		[theme.breakpoints.up("md")]: {
			borderRight: "3px solid #222 !important",
			borderWidth: "4px",
		},
		[theme.breakpoints.down("sm")]: {
			display: location.pathname === "/" ? "block" : "none",
		},
	}))

	return (
		<ScopedCssBaseline>
			<LayoutRoot maxWidth="xl">
				<LayoutContainer maxWidth="xl">
					<MenuAppBar />
					<ContainerPaper elevation={10}>
						<Hr style={{ width: "98.7%", marginLeft: 13 }} />
						<Grid container spacing={3}>
							<SideBarGrid item xs={12} sm={12} md={3}>
								<SideBar />
							</SideBarGrid>
							<MainContentGrid item xs={12} sm={12} md={9}>
								{children}
							</MainContentGrid>
						</Grid>
					</ContainerPaper>
				</LayoutContainer>
			</LayoutRoot>
			{!isSSR && (
				<React.Suspense fallback={<div />}>
					<FooterClientSideOnlyLazy />
				</React.Suspense>
			)}
		</ScopedCssBaseline>
	)
}

export { Hr }
